/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, PriceWrap, Subtitle, ContactForm, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Strojní čistění DPF filtrů "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--60" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72" content={"Čištění&nbsp; DPF filtrů&nbsp;<br>Strojní regenerace filtru pevných částic&nbsp;<br>"}>
              </Title>

              <Text className="text-box fs--18" content={"svítí vám na vozidle kontrolka ucpaného filtru pevných částic DPF ? Jede vaše auto v nouzovém režimu a má snížený výkon ?&nbsp; Nespouští se automaticky a přirozeně regenerace DPF filtru ?&nbsp; Tak je třeba filtr pevných části DPF demontovat a nechat strojně vyčistit. Zabýváme se čistěním filtru pevných části DPF ale i demontáži a následnou strojní regeneraci filtru.&nbsp;&nbsp;<br><br><span style=\"font-weight: bold; color: rgb(249, 8, 8);\">Tel : +420 777 321 587&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"Čištění probíhá na profesionální čistící lince s patentovanou technologií ISO 9001 a certifikací TUV. čistící stroj má&nbsp; vnitřním okruh kapaliny tudíž celý průběh čištění je naprosto ekologický a šetrný k životnímu prostředí. Ve srovnání s ostatními technologiemi jako je například vypalování, nebo ultrazvuk je tento způsob čištění nejúčinnější.\n\nDalší jeho nespornou výhodou je, že průběh čištění probíhá bez jakéhokoliv invazivního zásahu do konstrukce filtru. Do DPF filtru neřežeme nijak ho mechanicky nepoškodíme. Po dokončení čištění můžeme garantovat odstranění veškerých usazenin oleje, popílku a sazí&nbsp; z čištěného filtru. Po dokončení celé čistící a vysoušecí procedury na&nbsp; našem stroji&nbsp; můžou hodnoty propustnosti filtru dosáhnout až na 97% hodnot nového filtru!"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"fjgixgu7o4"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/ostatni/11a12d685aa91eb345db5281b3d18afb.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(235,45,45,1)"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">čištění a regenerace filtru DPF na klíč.<br></span>&nbsp;1) Po příjezdu k nám na dílnu provedeme kontrolu Vašeho vozu diagnostikou, jestli všechny systémy pracují tak jak mají.<br><br>2) Provedeme demontáž DPF/FAP/ nebo&nbsp; katalyzátoru z vašeho vozidla.<br><br>&nbsp;3) Provedeme kontrolu stavu filtru a jeho vložky endoskopickou kamerou jestli nejsou přepážky filtru porušené a spečené.<br><br>4) Provedeme tlakové měření průchodnosti filtru před čištěním a vytiskneme pro zákazníka protokol o stavu průchodnosti. Spustíme čištění chemickým roztokem.<br><br>5) Po úspěšném čištění provedeme sušení filtru horkým vzduchem až do úplného vysušení. Provedeme opět tlakové měření průchodnosti filtru po vyčištění a vytiskneme pro zákazníka protokol o stavu průchodnosti vyčištěného filtru.<br><span style=\"font-weight: bold;\"><br></span>&nbsp;6) Inspekční kamerou provedeme opět vizuální kontrolu stavu filtrační vložky Vašeho DPF filtru.<br><br>7)&nbsp; Namontujeme filtr zpět na automobil. A provedeme závěrečnou diagnostiku automobilu a případné smazání chybových hlášek.<br><span style=\"font-weight: bold;\"><br>Záruka na DPF/KAT&nbsp; 100 000 km / 2 roky&nbsp;<br><br></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/ostatni/4c1cb4131fe70ccf1ec75cafc99e2546.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(235,45,45,1)"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">POSTUP PRO ZAKÁZKY\nZASLANÉ PŘEPRAVOU<br></span><br>1) Vyplňte objednávkový formulář na objednání čištění vašeho filtru. <br>2) Odborně demontovaný filtr DPF nebo katalyzátor zabalte&nbsp; a přiložte vytištěnou objednávku včetně vašich kontaktních a fakturačních údajů.&nbsp;<br>3)&nbsp;Po obdržení vaší objednávky na svoz odešleme kurýra pro čištěné zařízení na Vaši adresu uvedenou v objednávkovém formuláři. Vyzvednutí kurýrem proběhne v pracovní dny do 24 hod. po přijetí objednávky.<br><br>4) Po přijetí Vašeho filtru DPF nebo katalyzátorů k čištění u nás na provozovně v Ostravě provedeme inspekční a čistící proces včetně vytištění všech protokolů pro zákazníka. Stejný jako pro zákazníky, kteří přijedou na čištění na provozovnu.<br><br>5)&nbsp;Vyčištěný&nbsp; a zabalený filtr pevných částic DPF nebo katalyzátor&nbsp; odešleme do 24 hod. včetně všech protokolů kurýrem na dobírku zpět k vám na adresu uvedenou v objednávkovém formuláři.<br><br><br><br><br><span style=\"font-weight: bold;\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/ostatni/83bb920be3d1ba03f998c40171ce8db7.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(235,45,45,1)"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Odpovědi na časté a opakující se dotazy ohledně DPF filtru.&nbsp;<br><br>CO JE DPF?<br></span>&nbsp;Jako DPF (někdy také FAP) se označuje filtr pevných částic. Jeho základní funkcí je zachycování mikročástic (sazí) z výfukových spalin. Do ovzduší se tak díky DPF dostává méně nečistot.&nbsp;<br><span style=\"font-weight: bold;\"><br>PROČ SE DPF UCPE?<br></span>&nbsp;Důvodů, proč se DPF filtr ucpe, může být několik. Nejčastější příčinou je vysoký nájezd vozu. Během provozu se ve voštinové struktuře filtru (kanálky uvnitř vložky DPF) postupně zanášejí sazemi z výfukových plynů. Aby se DPF filtr opět uvolnil, spouští řídící jednotka tzv. aktivní regenerace; jde o proces, při němž se uměle navyšuje teplota výfukových spalin, které následně usazené částice v DPF filtru vypálí. Tato regenerace není nikdy dokonalá. V DPF filtru zůstávají nespálené saze a jiné nečistoty, jež se v DPF filtru hromadí, a časem jej zcela ucpou. DPF filtr ztratí svoji funkčnost. Filtr DPF se může však ucpat i z jiných příčin, kupříkladu proto, že správně nefunguje řídící jednotka či jsou poškozené senzory, které sledují tlak výfukových plynů...&nbsp;<br><br><span style=\"font-weight: bold;\"><br><span style=\"color: rgb(251, 36, 5);\">JAK SE PROJEVUJE UCPANÉ DPF<br></span></span>\n<span style=\"text-decoration-line: underline;\"><br>Že má vozidlo problém s DPF filtrem, signalizuje několik faktorů:\n<br></span><br>-&nbsp; Svítí oranžová kontrolka DPF nebo kontrolka motoru<br>-&nbsp; Sníží se výkon motoru\nVůz má zvýšenou spotřebu\n<br>- Ztráta akcelerace\nČasté regenerace DPF\nNaskočení \"nouzového režimu\"<br><span style=\"font-weight: bold;\"><br><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"4451uvxkbm6"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"&nbsp;Orientační cena demontáže filtru DPF nebo katalyzátorů&nbsp; Osobní vozidlo - Dodávka - Nákladní vozidlo&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2900- 5000— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Orientační cena čistění fitru DPF nebo katalyzátorů&nbsp; Osobní vozidlo&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"3590,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Orientační cena čistění fitru DPF nebo katalyzátorů&nbsp; Dodávka&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"4200,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Orientační cena čistění fitru DPF nebo katalyzátorů Nákladní vozidlo&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"9500,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Orientační cena čistění fitru DPF nebo katalyzátorů pracovní stroj&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"9500,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"teyhiuoz01"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":650}} content={"*Uvedené ceny jsou včetně DPH.&nbsp; Cena práce je pouze orientační a je závislá na náročnosti, typu vozidla a použitého materiálu.\n\nStandardní cena dopravy filtru na osobní vozidlo je 240 Kč. Možnost&nbsp; zapůjčení náhradního vozu: 500 Kč / den\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"q96efpsesh"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Máte autoservis?&nbsp;"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left fs--20" content={"<span style=\"font-weight: bold; color: rgb(250, 7, 7);\">Staňte se naším partnerem. Máme pro Vás bezkonkurenční podmínky!</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"pkkposmtvyo"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Online objednávka čistění filtru DPF nebo katalyzátorů&nbsp;"}>
              </Title>

              <Subtitle className="subtitle-box mt--16" content={""}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":740}} content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Čistění DPF  filtrů včetně demontáže na klíč","type":"checkbox","required":false},{"name":"Vyzvednutí DPF filtrů kurýrem na dané adrese ","type":"checkbox"},{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Fakturační a dodací adresa pro vyzvednutí DPF filtru","type":"text","required":true,"placeholder":"Adresa : "},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","required":true,"placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1odcdv6 css-42e4bw pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" url={"https://autoservisblaza.cz/kontaktni-formular/"} href={"https://autoservisblaza.cz/kontaktni-formular/"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Autoservis Bláža Ostrava - Radvanice&nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-2);\">Pro Vaše auto uděláme vše, co budeme moct.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-custom-2);\">Jsme Malý a nezávislý autoservis  osobních i užitkových vozidel. \nMěření a seřízení geometrie kol \nServis čištění a plnění klimatizací \nRychloservis\nPneuservis\nNástřiky podvozku a dutin \nStrojní regenerace filtru pevných částic DPF\na jiné.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-custom-2);\">+420 777 321 587\nLihovarská  1378/11,  Ostrava - Radvanice \nAreál Bánských strojíren ( BASTRO )<br>Tel :<a href=\"+420777321587\">&nbsp;+420777321587</a><br>&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">IČ: 63471710&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}